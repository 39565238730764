import Icons from './Icon.list';
import {ValidatorConstraint} from "class-validator";

@ValidatorConstraint()
export class IconConstraint {
  validate(value) {
    return Object.values(Icons).includes(value);
  }

  defaultMessage() {
    return 'Value "$value" is not a part of Icons list!';
  }
}

<template>
  <section class="AccountSelect">
    <InputDropdown
        v-model="$store.state.user.account"
        :searchable="false"
        :show-labels="false"
        :options="accounts"
        :allow-empty="false"
        track-by="id"
        track-label="shortSelectName"
        tooltip="name"
        close-on-select
    />
  </section>
</template>

<script>
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import {LOCAL_STORE_ACCOUNT, LOCAL_STORE_SEASON} from '@/local-store.keys';
import {UserAccountModel} from "@/classes/models/UserAccount.model";

export default {
  name: 'DDMCAF-AccountSelect',
  components: {InputDropdown},
  computed: {
    accounts() {
      return get(this, '$store.state.user.accounts.models', [])
    },
    account() {
      return get(this, '$store.state.user.account', []);
    }
  },
  created() {
    this.evaluateAccountValue();
  },
  watch: {
    accounts(accounts) {
      this.evaluateAccountValue(accounts)
    },
    account(user, oldValue) {
      window.localStorage.setItem(LOCAL_STORE_ACCOUNT, user)

      if (!!user && !!oldValue && oldValue.id !== user.id) {
        window.localStorage.setItem(LOCAL_STORE_SEASON, null)
      }

      if (
          !!user
          &&
          !!oldValue
          &&
          oldValue.id !== user.id
          &&
          !(window.location.href.indexOf("/home") > -1)
      ) {
        document.location.href = "/home";
      }

      this.getSeasons()
    }
  },
  methods: {
    getSeasons() {
      this.$store.dispatch('event/getSeasons')
    },
    evaluateAccountValue(accounts = this.accounts) {
      let localStorageValue = this.getAccountFromLocaleStorage()

      this.$store.state.user.account = localStorageValue ? localStorageValue : this.getDefaultAccountValue(accounts)
    },

    getAccountFromLocaleStorage() {
      let accounObject = JSON.parse(window.localStorage.getItem(LOCAL_STORE_ACCOUNT));
      let result = null
      if (!!accounObject) {
        result = new UserAccountModel(
            accounObject.name,
            accounObject.id,
            accounObject.categories,
            accounObject.competitions ? accounObject.competitions : [],
            accounObject.permissions
        );
      }

      return result
    },

    getDefaultAccountValue(accounts) {
      return isEmpty(accounts) ? null : accounts[0];
    }
  }
}
</script>

<style lang="scss">
.AccountSelect {
}
</style>

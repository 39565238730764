<template>
  <section class="AccountSelect">
    <InputDropdown
        v-model="$store.state.event.selectedSeason"
        placeholder="Search"
        :searchable="true"
        :show-labels="false"
        :options="seasons"
        :allow-empty="false"
        track-by="seasonId"
        track-label="shortLabel"
        tooltip="title"
        close-on-select
    />
  </section>
</template>

<script>
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import {LOCAL_STORE_SEASON} from "@/local-store.keys";

export default {
  name: 'DDMCAF-LeagueSelect',
  components: {InputDropdown},
  computed: {
    seasons() {
      let seasons = get(this, '$store.state.event.seasonsList', [])
      if (seasons.length !== 0) {
        seasons = seasons.map((item) => {
          item.shortLabel = item.title.length > 25 ? item.title.slice(0, 25) + '...' : item.title
          return item
        })
      }

      return seasons
    },
    season() {
      return get(this, '$store.state.event.selectedSeason', null)
    }
  },
  created() {
    this.evaluateLeagueValue();
  },
  watch: {
    seasons(seasons) {
      this.evaluateLeagueValue(seasons);
    },
    season(season, oldVal) {
      this.setSeasonToLocaleStorage(season)

      if (
          !!season
          &&
          !!oldVal
          &&
          !!oldVal.seasonId
          &&
          parseInt(oldVal.seasonId) !== parseInt(season.seasonId)
          &&
          !(window.location.href.indexOf("/home") > -1)
      ) {
        document.location.href = "/home";
      }

      if (season) {
        this.getMatchDays()
      }
    }
  },
  methods: {
    getMatchDays() {
      this.$store.dispatch('event/getMatchDays')
    },
    evaluateLeagueValue(seasons = this.seasons) {
      let localStorageValue = this.getSeasonFromLocaleStorage()

      if (localStorageValue && seasons.length) {
        const updatedValue = seasons.find(season => season.seasonId === localStorageValue.seasonId);
        if (updatedValue) {
          localStorageValue = updatedValue;
          this.setSeasonToLocaleStorage(updatedValue);
        }
      }

      this.$store.state.event.selectedSeason = !!localStorageValue ? localStorageValue : this.getDefaultSeasonValue(seasons)
    },
    getSeasonFromLocaleStorage() {
      let seasonData = JSON.parse(window.localStorage.getItem(LOCAL_STORE_SEASON));

      return !!seasonData ? seasonData : null
    },
    setSeasonToLocaleStorage(season) {
      return window.localStorage.setItem(LOCAL_STORE_SEASON, JSON.stringify(season))
    },
    getDefaultSeasonValue(seasons) {
      return isEmpty(seasons) ? null : seasons[0];
    }
  }
}
</script>

<style lang="scss">
.AccountSelect {
}
</style>

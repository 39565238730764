<template>
  <section class="NavigationLink">
    <div class="NavigationLink__box" @click="link.callback()">
      <router-link v-if="link.redirect" class="NavigationLink__link" :to="{ name: link.redirect }">
        <Icon v-if="link.icon" class="NavigationLink__icon" :icon="link.icon" size="18px"/>
        <span class="NavigationLink__label">{{ link.text }}</span>
      </router-link>
      <div v-if="!link.redirect" class="NavigationLink__link">
        <Icon v-if="link.icon" class="NavigationLink__icon" :icon="link.icon" size="18px"/>
        <span class="NavigationLink__label">{{ link.text }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '@/components/Icon/Icon.component';
import {RouterLinkModel} from "@/components/NavigationSidebar/classes/RouterLink.model";

export default {
  name: 'DDMCAF-NavigationLink',
  components: {Icon},
  props: {
    link: { type: RouterLinkModel, validator: model => model.validate() }
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/main.scss";

.NavigationLink {
  &__label {
    margin-left: 10px;
    font-weight: bold;
  }
  &__link {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 13.5px 16px;
    color: white;
    text-decoration: none;
    font-size: 12px;

    .NavigationLink__icon {color: white !important}

    &.router-link-active {
      background-color: $secondary-color;
      color: white;
      font-weight: 800;

      .NavigationLink__icon {color: $danger-color !important}

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 5px;
      }
    }
  }
}
</style>

<template>
  <section class="Header">
    <router-link :to="{ name: $static.RouterNames.Home }">
      <Logo class="Header__logo"/>
    </router-link>
  </section>
</template>

<script>

import Logo from '@/components/Logo/Logo.component';
import RouterNames from '@/router/route.names';
import set from "lodash.set";

export default {
  name: 'DDMCAF-Header',
  components: {Logo},
  data: () => ({

  }),
  methods: {

  },
  created() {
    set(this, '$static.RouterNames', RouterNames);
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/main.scss";

.Header {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  height: 66px;
  width: 100%;
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    width: 100px;
  }
}
</style>

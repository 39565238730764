import {IsOptional, IsString, Validate} from "class-validator";
import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IconConstraint} from "@/components/Icon/Icon.constraint";

export class RouterLinkModel extends ModelAbstraction {
  @IsString()
  text;

  @IsString()
  @IsOptional()
  redirect;

  @Validate(IconConstraint)
  @IsOptional()
  @IsString()
  icon;

  callback;

  permissionCheck;

  constructor(text, redirect, icon, callback = () => {}, permissionCheck = null) {
    super();
    this.text = text;
    this.redirect = redirect;
    this.icon = icon;
    this.callback = callback;
    this.permissionCheck = permissionCheck;
  }
}

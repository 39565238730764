<template>
  <section class="Logo">
    <img class="Logo__image" :src="$static.images.Logo" alt="Logo">
  </section>
</template>

<script>
import Logo from '@/assets/DDMCAF/images/logo_white.svg';
import set from 'lodash.set';

export default {
  name: 'DDMCAF-Logo',
  created() {
    set(this, '$static.images.Logo', Logo);
  }
}
</script>

<style lang="scss" scoped>
.Logo {
  width: 100%;

  &__image {
    width: 100%;
  }
}
</style>

<template>
  <section class="Index-View">
    <div class="Index-View__workspace" id="app">
      <Slide width="255" @openMenu="handleOpenSidebar" disableOutsideClick noOverlay
             isOpen
            @closeMenu="handleCloseSidebar">
        <div class="Index-View__workspace-column Index-View__workspace-column_left">
          <NavigationSidebar/>

          <div class="contact-us-link">
            Need help? Contact:<br><a href="mailto:pspsupport@asiafootballgroup.com">pspsupport@asiafootballgroup.com</a>
          </div>
        </div>

      </Slide>
      <div id="page-wrap" :class="(!isSidebarOpened ? 'opened-hamburger ' : '') + 'Index-View__workspace-column Index-View__workspace-column_right'" v-if="account">
        <Header v-if="!isSidebarOpened"/>

        <Breadcrumbs/>
        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar/NavigationSidebar.component';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.component';
import Header from '@/components/Header/Header.component';
import set from "lodash.set";
import get from "lodash.get";
import {Slide} from 'vue-burger-menu'

export default {
  name: 'DDMCAF-IndexView',
  components: {
    NavigationSidebar,
    Breadcrumbs,
    Header,
    Slide,
  },
  data: () => ({
    isSidebarOpened: true,
  }),
  computed: {
    account() {
      return get(this, '$store.state.user.account');
    }
  },
  created() {
    set(this, '$static.images', {shape});
  },
  methods: {
    handleOpenSidebar() {
      this.isSidebarOpened = true
    },
    handleCloseSidebar() {
      this.isSidebarOpened = false
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

b {
  font-weight: 600;
}
.policy-text {
  padding: 10px;
  text-align: center;
  font-size: 12px;
}

.contact-us-link {
  padding-left: 20px;
  padding-bottom: 10px;
  margin-top:auto;
  z-index: 2;

  font-size: 12px;
  color: white;

  a {
    color: white;
    font-weight: 600;
  }
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.opened-hamburger {
  padding-top: 80px !important;
}

.Index-View {
  @include fill();


  // humburger menu styles start
  .bm-item-list {
    margin-left: 0 ;
  }
  .bm-menu {
    padding-top: 0;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: red;
      outline: 1px solid slategrey;
    }
  }
  .bm-item-list>* {
    display: block;
    padding: 0;
  }
  .bm-item-list {
    height: 100%;
  }
  .bm-cross-button {
    left: 30px;
    top: 15px;
    height: 24px;
    width: 24px;
    z-index: 2;

    .bm-cross{
      background: white;
    }
  }
  .bm-burger-button {
    z-index: 101;
    top: 20px;
    left: 24px;
    width: 24px;
    height: 24px;
    .bm-burger-bars {
      background: white;
    }
  }
  .bm-menu {
    float: left;
    position: relative;
    height: 100vh;
  }

    // humburger menu styles end

  &__workspace {
    @include fill();

    &-shape {
      position: absolute;
      bottom: 0;
      width: 210px;
    }

    &-column {
      height: 100%;
      max-height: 100%;
      overflow: auto;

      &_left {
        width: $sidenav_width;
        background-color: $primary-color;
        position: relative;

        display: flex !important;
        flex-flow: column nowrap;
      }

      &_right {
        background-color: #F4F6FC;
        padding: 55px 30px;
        box-sizing: border-box;
      }
    }
  }
}
</style>

<template>
  <section class="Collapse">
    <div class="Collapse__toggle">
      <div class="Collapse__toggle-wrapper" @click="onClick">
        <div class="Collapse__toggle-item">
          <Icon class="Collapse__toggle-icon" v-if="icon" :icon="icon"/>
          <span class="Collapse__toggle-text">{{ text }}</span>
        </div>
        <div class="Collapse__toggle-item">
          <div :class="[
            'Collapse__toggle-dropdown-icon',
            { 'Collapse__toggle-dropdown-icon_up': shouldOpen },
            { 'Collapse__toggle-dropdown-icon_down': !shouldOpen }
          ]"/>
        </div>
      </div>
      <div :id="$static.collapse.id" v-if="shouldOpen">
        <slot/>
      </div>
    </div>
  </section>
</template>

<script>
import set from "lodash.set";
import {v4 as uuidGenerator} from 'uuid';
import Icon from "@/components/Icon/Icon.component";

export default {
  name: 'DDMCAF-Collapse',
  components: {Icon},
  props: {
    icon: {type: String, required: false},
    text: {type: String, required: true},
    shouldOpen: {type: Boolean, required: false, default: false},
  },
  computed: {
    dropdownIcon() {
      const {up, down} = this.$static.collapse.icons;
      return this.shouldOpen ? up : down;
    },
  },
  created() {
    const uuid = uuidGenerator();
    set(this, '$static.collapse.id', `Collapse-${uuid}`);
    // set(this, '$static.collapse.icons', {up, down});
  },
  methods: {
    onClick: function() {
      this.$emit('clicked')
    }
  }
}
</script>

<style lang="scss">
.Collapse {
  &__toggle {

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    &-item {
      display: flex;
      align-items: center;
    }

    &-icon {
      font-size: 18px !important;
    }

    &-icon, &-text {
      cursor: pointer;
      color: white !important;
    }
    &-text {
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
      margin-left: 10px;
      font-weight: bold;
    }
  }

  &__toggle-dropdown-icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: white transparent transparent transparent;

    &_down {transform: rotate(0deg)}
    &_up {transform: rotate(180deg)}
  }
}
</style>

<template>
  <section class="UserProfileView">
    <span class="UserProfileView__name UserProfileView__name_pre"> Logged in as </span>
    <span class="UserProfileView__name UserProfileView__name_text"> {{ name }} </span>
  </section>
</template>

<script>
import get from 'lodash.get'

export default {
  name: 'DDMCAF-UserProfileView',
  computed: {
    name() { return get(this, '$store.state.user.model.name') }
  }
}
</script>

<style lang="scss">
.UserProfileView {
  &__name {
    color: white;
    font-size: 12px;

    &_text {font-weight: bold}
  }
}
</style>

import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {RouterLinkModel} from "@/components/NavigationSidebar/classes/RouterLink.model";
import {IsInstance, ValidateNested} from "class-validator";

export class RouterLinkCollection extends CollectionAbstraction {
  @IsInstance(RouterLinkModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }
}

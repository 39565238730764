<template>
  <section class="NavigationSidebar">
    <router-link :to="{ name: $static.RouterNames.Home }">
      <Logo class="NavigationSidebar__logo"/>
    </router-link>

    <UserProfileView class="NavigationSidebar__user-profile"/>

    <AccountSelect class="NavigationSidebar__account-select"/>
    <LeagueSelect class="NavigationSidebar__league"/>
    <div class="clearfix"></div>

    <template v-for="(collapse, index) in collapses">
      <Collapse :shouldOpen="collapse.open" v-if="$store.getters[collapse.permissionKey]"
                :key="index"
                class="NavigationSidebar__dropdown"
                :text="collapse.text"
                :icon="$static.links.Icons[collapse.icon]"
                @clicked="collapseClick(collapse)"
      >
        <NavigationLinkList class="NavigationSidebar__list" :links="$static.links[collapse.links]"/>
      </Collapse>
    </template>

    <NavigationLinkList class="NavigationSidebar__list" :links="$static.links.General"/>
  </section>
</template>

<script>
import Icons from '@/components/Icon/Icon.list';
import RouterNames from '@/router/route.names';
import Logo from '@/components/Logo/Logo.component';
import UserProfileView from './components/UserProfileView.component';
import AccountSelect from './components/AccountSelect.component';
import LeagueSelect from './components/LeagueSelect.component';
import Collapse from '@/components/Collapse/Collapse.component';
import NavigationLinkList from './components/NavigationLinkList.component';
import {RouterLinkCollection} from '@/components/NavigationSidebar/classes/RouterLink.collection';
import {RouterLinkModel} from '@/components/NavigationSidebar/classes/RouterLink.model';
import set from 'lodash.set';

export default {
  name: 'DDMCAF-NavigationSidebar',
  components: {Logo, UserProfileView, AccountSelect, LeagueSelect, Collapse, NavigationLinkList},
  data: () => ({
    collapses: [
      {id: 1, open: false, permissionKey: 'user/haveAccessToAccreditation', icon: 'ACCREDITATION', text: "Accreditation Management", links: 'Accreditation'},
      {id: 2, open: false, permissionKey: 'user/haveAccessToTickets', icon: 'TICKET', text: "Ticket Management", links: 'Tickets'},
      {id: 3, open: false, permissionKey: 'user/haveAccessToActivations', icon: 'PARTNER', text: "Partner Activation", links: 'SponsorActivation'}
    ]
  }),
  methods: {
    collapseClick(collapse) {
      this.collapses.forEach(item => {
        if (collapse.id !== item.id) {
          item.open = false
        }
      })

      collapse.open = !collapse.open
    }
  },
  created() {
    set(this, '$static.RouterNames', RouterNames);
    set(this, '$static.links.Icons', Icons);
    set(this, '$static.links.Empty', new RouterLinkCollection([]));

    set(this, '$static.links.Accreditation', new RouterLinkCollection([
      new RouterLinkModel('Person Management', RouterNames.IndexRoutes.DashboardRoutes.Person, Icons.TEAM),
      new RouterLinkModel(
          'Accreditation Requests',
          RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestList,
          Icons.REQUESTS,
          () => {
            this.$store.commit('accreditationRequest/clearState')
          }
      ),
    ]));

    set(this, '$static.links.Tickets', new RouterLinkCollection([
      new RouterLinkModel(
          'Main Contacts',
          RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.MainContacts,
          Icons.PERSON
      ),
      new RouterLinkModel(
          'Delivery Contacts',
          RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.DeliveryAddress,
          Icons.PERSON
      ),
      new RouterLinkModel('Ticket Requests',
          RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestList,
          Icons.REQUESTS
      ),
    ]));

    set(this, '$static.links.SponsorActivation', new RouterLinkCollection([
      new RouterLinkModel(
          'Activation Requests',
          RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationList,
          Icons.REQUESTS,
          () => {
            this.$store.commit('sponsorActivation/clearState')
          }),
    ]));

    set(this, '$static.links.General', new RouterLinkCollection([
      new RouterLinkModel('Broadcaster Booking', '', Icons.RADAR, () => {
        window.open(this.$store.getters['user/getBroadcasterBookingUrl'], '_blank');
      }, 'user/haveAccessToBroadcasterBooking'),
      new RouterLinkModel('Approvals', '', Icons.APPROVAL,() => {
        window.open('https://theafg.sharepoint.com/sites/BrandInformationCentre46/', '_blank');
      }, 'user/haveAccessToApprovals'),
      new RouterLinkModel('Information Centre', '', Icons.BRAND_ASSETS,() => {
        window.open('https://theafg.sharepoint.com/sites/BrandInformationCentre46/', '_blank');
      }, 'user/haveAccessToBrandAccess'),
      new RouterLinkModel('My Profile', RouterNames.MyProfile, Icons.PERSON),
      new RouterLinkModel('Log out', null, Icons.LOG_OUT, () => this.$store.commit('api/logout')),
    ]));
  }
}
</script>

<style lang="scss" scoped>
.NavigationSidebar {
  position: relative;
  z-index: 1;

  &__list:not(:last-of-type) {
    margin-bottom: 33px
  }

  &__account-select {
    margin: 22px 16px 20px
  }

  &__league {
    margin: 16px 16px 32px;
  }

  &__user-profile {
    margin-top: 48px;
    margin-left: 16px;
  }

  &__logo {
    padding: 22px 25px 0 33px;
    width: 229px;
    box-sizing: border-box;
    margin: 30px 0;
  }

  ::v-deep .NavigationLinkList__headline {
    margin-bottom: 0;
  }

  &__dropdown {
    margin: 13.5px 0 0;
    padding-bottom: 13.5px;

    ::v-deep .Collapse__toggle-wrapper {
      margin: 0 17px
    }

    ::v-deep .NavigationLink__link {
      padding-left: 32px
    }

    ::v-deep .NavigationLinkList__list-box:first-of-type .NavigationLink__link {
      margin-top: 13.5px
    }

    //::v-deep .NavigationLinkList__list-box:last-of-type .NavigationLink__link {padding-bottom: 0}
  }
}
</style>

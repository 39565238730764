<template>
  <section class="NavigationLinkList">
    <div class="NavigationLinkList__headline">
      {{ headline }}
    </div>
    <div class="NavigationLinkList__list">
      <div
          class="NavigationLinkList__list-box"
          v-for="(link, index) in links.models"
          :key="`NavigationLinkList-${headline}-${index}`"
      >
        <navigation-link :link="link" v-if="!link.permissionCheck || $store.getters[link.permissionCheck]"/>
      </div>
    </div>
  </section>
</template>

<script>
import NavigationLink from './NavigationLink.component';
import {RouterLinkCollection} from "@/components/NavigationSidebar/classes/RouterLink.collection";

export default {
  name: 'DDMCAF-NavigationLinkList',
  props: {
    headline: String,
    links: {type: RouterLinkCollection, validator: collection => collection.validate()}
  },
  components: {NavigationLink}
}
</script>

<style lang="scss" scoped>
.NavigationLinkList {

  &__headline {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: bold;
    margin: 0 16px 12px;
    opacity: 0.71;
  }
}
</style>

<template>
  <section class="Breadcrumbs">
    <div
      class="Breadcrumbs__section"
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="`breadcrumb-${breadcrumb.name}`"
    >
      <router-link
        class="Breadcrumbs__link"
        :class="{
          'Breadcrumbs__link_current': ($router.currentRoute.name === breadcrumb.name) || ($router.currentRoute.name === get(breadcrumb, 'redirect.name')),
          'Breadcrumbs__link_inactive': !breadcrumb.active
        }"
        :to="{name: (breadcrumb.active) ? (breadcrumb.redirectToNamedRoute || breadcrumb.name) : ''}"
      >
        {{ extractBreadcrumbsText(breadcrumb.text) }}
      </router-link>
      <span v-if="(index + 1) !== breadcrumbs.length" class="Breadcrumbs__spacer"> > </span>
    </div>
  </section>
</template>

<script>
import get from 'lodash.get';
import template from 'lodash.template';

export default {
  name: 'DDMCAF-Breadcrumbs',
  data: () => ({ breadcrumbs: [] }),
  watch: {
    '$route.fullPath'() { this.extractBreadcrumbsFromRouter() }
  },
  created() { this.extractBreadcrumbsFromRouter() },
  methods: {
    get,
    extractBreadcrumbsFromRouter() {
      this.breadcrumbs = this.$router.currentRoute.matched
        .filter(route => !!get(route, 'props.default.breadcrumbs'))
        .map(({props, redirect, name}) => ({ name, redirect, ...get(props, 'default.breadcrumbs')}));
      return this;
    },
    extractBreadcrumbsText(text) {
      const {$route} = this;
      return template(text)({$route});
    }
  }
}
</script>

<style lang="scss" scoped>
.Breadcrumbs {
  display: flex;
  font-size: 12px;
  margin-bottom: 32px;

  &__link {
    text-transform: uppercase;
    text-decoration: none;
    color: #8D8E92;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }

    &_current { color: #1F1F1F }
    &_inactive, &_current { cursor: default }
  }

  &__spacer {
    margin: 0 10px;
    cursor: default;
    color: #8D8E92;
  }
}
</style>
